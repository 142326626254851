/*
https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: var(--color-ab-brown);
  background-color: white;
  border: 0;
  border-bottom: 1px solid var(--color-ab-yellow);
}

.StripeElement--focus {
  border-bottom: 1px solid var(--color-ab-green);
}

.StripeElement--invalid {
  border-color: var(--color-ab-red);
}

.StripeElement--webkit-autofill {
  background-color: var(--color-ab-red) !important;
}
