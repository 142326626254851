.loading-indicator > span {
  height: 5px;
}

.loading-indicator > .indicator1 {
  animation: increase 2s infinite;
}

.loading-indicator > .indicator2 {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
 from { left: -5%; width: 5%; }
 to { left: 130%; width: 100%;}
}

@keyframes decrease {
 from { left: -80%; width: 80%; }
 to { left: 110%; width: 10%;}
}
