.nav-btn {
  font-size: 18px;
  font-weight: 600;
  padding: 7px 40px 7px 35px;
}
.nav-btn:hover {
  text-decoration: underline;
}

.nav-btn.next {
  background: url("../../../public/static/images/btn-arrow-green.svg") 85%
    center no-repeat var(--color-ab-green);
  color: var(--color-ab-white);
}
.nav-btn.next:hover {
  background-position: 90%;
}

.nav-btn.help {
  padding: 7px 25px;
}
